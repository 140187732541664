<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-card :title="heading">

                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                        <div class="alert-body">
                            {{error_message}}
                        </div>
                    </b-alert>
                    
                    <b-form @submit.prevent="formSubmit">
                        <b-row>

                            <b-col md="2">
                                <EditImageComponent :image="form.image"/>
                            </b-col>

                            <b-col md="10">
                                <b-row>
                                    <b-col md="6">
                                        <b-form-group
                                          label="Category Name"
                                          class="required half_rem_one_five"
                                        >
                                            <b-form-input placeholder="3 to 200 characters" v-model="form.name" />

                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6">
                                        <b-form-group label="Category Type" class="required half_rem_one_five">
                                            <v-select
                                                v-model="form.category_type"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="['Normal', 'Security']"
                                                placeholder="Select"
                                                :clearable="false"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <hr>

                                <div class="fmTable table-responsive" style="margin-bottom: 0px !important;">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="width: 35%">Project Sites</th>
                                                <th scope="col" style="width: 60%">Location</th>
                                                <th scope="col" style="width: 5%"></th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="$store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'site_client' || $store.getters.currentUser.role == 'site_manager' || $store.getters.currentUser.role == 'administrator' || $store.getters.currentUser.role == 'crew'">
                                            <tr v-if="((ps.site == null) || (( $store.getters.currentUser.om_sites.indexOf(ps.site._id) >= 0) && ps.site.status == 'active' )) " v-for="(ps,index) in form.locData" :key="index">
                                                <td>
                                                    <v-select
                                                        v-model="ps.site"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        label="site_name"
                                                        :options="sites"
                                                        placeholder="Select"
                                                        @input=resetData(index)
                                                        :clearable="false"
                                                        class="my-1"
                                                        :appendToBody=true
                                                    />
                                                </td>
                                                <td>
                                                    <v-select
                                                        v-model="ps.locations"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        multiple
                                                        label="name"
                                                        :options="ps.all_locations"
                                                        placeholder="Select"
                                                        class="my-1"
                                                        :appendToBody=true
                                                        :close-on-select=false
                            :clear-on-select=false
                                                    />
                                                </td>
                                                <td>
                                                    <b-button variant="danger" @click="removeRow(index);">-</b-button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(ps,index) in form.locData" v-if="(ps.site == null || ps.site && ps.site.status == 'active')" :key="index">
                                                <td>
                                                    <v-select
                                                        v-model="ps.site"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        label="site_name"
                                                        :options="sites"
                                                        placeholder="Select"
                                                        @input=resetData(index)
                                                        :clearable="false"
                                                        class="my-1"
                                                        :appendToBody=true
                                                    />
                                                </td>
                                                <td>
                                                    <v-select
                                                        v-model="ps.locations"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        multiple
                                                        label="name"
                                                        :options="ps.all_locations"
                                                        placeholder="Select"
                                                        class="my-1"
                                                        :appendToBody=true
                                                        :close-on-select=false
                            :clear-on-select=false
                                                    />
                                                </td>
                                                <td>
                                                    <b-button variant="danger" @click="removeRow(index);" class="mt-1">-</b-button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <!-- <b-row class="mb-1">

                                    <b-col lg="4">
                                        <h5>Project Sites</h5>
                                    </b-col>
                                    <b-col lg="8">
                                        <h5>Location</h5>
                                    </b-col>
                                </b-row>
                                <div v-if="$store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'site_client' || $store.getters.currentUser.role == 'site_manager' || $store.getters.currentUser.role == 'administrator' || $store.getters.currentUser.role == 'crew'">
                                    <b-row v-if="((ps.site == null) || (( $store.getters.currentUser.om_sites.indexOf(ps.site._id) >= 0) && ps.site.status == 'active' ))" v-for="(ps,index) in form.locData" :key="index" class="mb-2">

                                        <b-col md="4">
                                            <v-select
                                                v-model="ps.site"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                label="site_name"
                                                :options="sites"
                                                placeholder="Select"
                                                @input=resetData(index)
                                                :clearable="false"
                                                class="mb-1"
                                            />
                                        </b-col>
                                        <b-col md="7">
                                            
                                            <v-select
                                                v-model="ps.locations"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                label="name"
                                                :options="ps.all_locations"
                                                placeholder="Select"
                                                class="mb-1"
                                              />
                                            
                                            
                                        </b-col>

                                        <b-col md="1" class="text-center">
                                            <b-button variant="danger" @click="removeRow(index);">-</b-button>
                                        </b-col>
                                    </b-row>
                                </div>

                                <div v-else>
                                    <b-row v-for="(ps,index) in form.locData" v-if="(ps.site == null || ps.site && ps.site.status == 'active')" :key="index" class="mb-2">

                                        <b-col md="4">
                                            <v-select
                                                v-model="ps.site"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                label="site_name"
                                                :options="sites"
                                                placeholder="Select"
                                                @input=resetData(index)
                                                :clearable="false"
                                                class="mb-1"
                                            />
                                        </b-col>
                                        <b-col md="7">
                                            
                                            <v-select
                                                v-model="ps.locations"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                label="name"
                                                :options="ps.all_locations"
                                                placeholder="Select"
                                                class="mb-1"
                                              />
                                            
                                            
                                        </b-col>

                                        <b-col md="1" class="text-center">
                                            <b-button variant="danger" @click="removeRow(index);">-</b-button>
                                        </b-col>
                                    </b-row>
                                </div> -->


                                <b-row>
                                    <b-col md="12" class="text-center">
                                        <hr>
                                        <b-button class="mt-1 add_more_mrgn_mobile" variant="primary" @click="addRow">Add More</b-button>
                                    </b-col>
                                </b-row>


                                <b-row class="mt-1">
                                    <b-col>
                                        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1">
                                            Submit
                                        </b-button>

                                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'location-category' })">
                                            Cancel
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>

                        </b-row>

                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import vSelect from 'vue-select';
import EditImageComponent from './EditImageComponent.vue';
import Bus from "../../../event-bus";

export default {
    components: {
        BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile,EditImageComponent,BBreadcrumb
    },

    directives: {
        Ripple,
    },

    data() {
        return {        
            error_message:null,
            showDismissibleAlert:false,

            sites:[],
            
            form :{
                image : {
                    image:null,
                    name:''
                },
                name : '',
                category_type: '',
                locData:[{
                    site:null,
                    locations:[],
                    all_locations:[],
                }],
                id:null

            },

            // Page Labels
            heading : 'Edit QR Categories',
            api     : '/api/update-qr-categories',
        }
    },

    methods : {
        formSubmit(){

            return this.$store.dispatch(POST_API, {
                data:{
                    items:this.form
                },
                api : this.api
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message        = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;

                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;

                    this.successAlert().then((result) => {
                        // this.$router.go(-1);
                        this.$router.push({ name:'location-category' })
                    });
                }
            });
        },

        allSites(id){
            return this.$store.dispatch(POST_API, {
                data:{
                    role:this.$store.getters.currentUser.role,
                    om_sites:this.$store.getters.currentUser.om_sites,
                },
                api: '/api/all-sites'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    this.sites = data;
                    return this.sites;
                }
            });
        },
        resetData(index){
            
            this.form.locData[index].locations = [];
            this.form.locData[index].all_locations = [];

            if(this.form.locData[index].site){
                this.allLocations(this.form.locData[index].site._id,index)
            }
        },
        allLocations(id,index){
            
          return this.$store.dispatch(POST_API, {
               data:{
                 site: id
               },
               api: '/api/all-locations'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    this.form.locData[index].all_locations = data;
                    return this.form.locData[index].all_locations;
                }
            });
        },
        addRow(){
            this.form.locData.push({
                site:null,
                locations:[],
                all_locations:[],
            })
        },

        removeRow(index) {
            this.form.locData.splice(index, 1);
            if(this.form.locData.length == 0){

                this.form.locData.push({
                    site:null,
                    locations:[],
                    all_locations:[],
                })
            }
        },
        categoryDetail(){
            return this.$store.dispatch(POST_API, {
               data:{
                 id: this.$route.params.id
               },
               api: '/api/qr-category-detail'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    this.form.image = {
                        image:data.image+'?'+new Date(),
                        name:''
                    };
                    
                    this.form.name = data.name;
                    this.form.category_type = data.category_type;

                    this.form.locData = data.site_locations.length > 0 ? data.site_locations : [{
                        site:null,
                        locations:[],
                        all_locations:[],
                    }];

                    this.form.id = data._id;

                    return this.form;
                }
            });
        },
        
        breadCrumb(){
            var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
            },{
                to:null,
                text: 'Feedback System',
            },{
                to:null,
                text: 'Settings',
            },{
                to:{name:'location-category'},
                text:'Category',
            },{
                to:null,
                text:'Edit QR Categories',
                active:true
            }];
            return item;
        },

        
    },
    mounted(){
        this.allSites();
        this.categoryDetail();
        Bus.$on('update-image', (data) => {
            
            this.form.image = data.data;
        })
    }
}
</script>
